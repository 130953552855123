import request from '../request'

//公共考核标题列表
export const common_title_index_request = p => {
    return request({
        method:'GET',
        url:'common_title/index',
        params: p
    })
}

//新增公共考核标题
export const common_title_add_request = d => {
    return request({
        method:'POST',
        url:'common_title/add',
        data: d
    })
}

//删除公共考核标题
export const common_title_del_request = id => {
    return request({
        method:'DELETE',
        url:'common_title/del',
        data: {
            id: id
        }
    })
}

//修改公共考核标题
export const common_title_edit_request = d => {
    return request({
        method:'PUT',
        url:'common_title/edit',
        data: d
    })
}

//公共考核标题详情
export const common_title_details_request = id => {
    return request({
        method:'GET',
        url:'common_title/details',
        params: {
            id
        }
    })
}